import React from 'react';
import { Link as LinkForScroll } from 'react-scroll';
import arrowSvg from '../icons/arrow.svg';
import topImageJpg from '../images/background.jpg';
import profileLogo from '../images/profile.png';

const TopSection = () => {
  const styles = {
    container: {
      alignItems: 'center',
      background: `#1E2749 url("${topImageJpg}") no-repeat center center`,
      backgroundSize: 'cover',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'center',
      paddingTop: '40px',
    },
    logo: {
      width: '200px',
      maxWidth: '80%',
      '@media (max-width: 800px)': {
        width: '150px',
      },
    },
    description: {
      color: '#fff',
      textAlign: 'center',
      fontSize: '28px',
      '@media (max-width: 800px)': {
        fontSize: '18px',
      },
    },
    arrow: {
      bottom: 0,
      cursor: 'pointer',
      marginBottom: '54px',
      position: 'absolute',
      width: '45px',
      animation: 'downArrow 0.8s ease-out infinite',
    },
  };

  return (
    <section id="top" css={styles.container}>
      <h1 css={styles.logo}>
        <img src={profileLogo} alt="logo of my site" />
      </h1>
      <div css={styles.description}>
        Hi! I&#39;m Nitesh Sood.
        <br />
        Software Developer and <br />
        Fitness Enthusiast.
      </div>
      <LinkForScroll
        to="profile"
        smooth
        duration={150}
        offset={-50}
        css={styles.arrow}
      >
        <img src={arrowSvg} alt="down arrow" />
      </LinkForScroll>
    </section>
  );
};

export default TopSection;
