import React from 'react';
import { Button, Form, Input, message } from 'antd';

import 'antd/dist/antd.css';
import './form.css';

class ContactFormComp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sendDisabled: false,
    };
  }

  resetFormFields = () => {
    this.formRef.current.resetFields();
  };

  formRef = React.createRef();

  render() {
    function encode(data) {
      return Object.keys(data)
        .map(
          key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
        )
        .join('&');
    }

    const layout = {
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        span: 21,
      },
    };

    const tailLayout = {
      wrapperCol: { offset: 3, span: 12 },
    };

    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not validate email!',
      },
    };
    const key = 'updatable';

    function handleNetworkError(e) {
      console.log(`submit Error : ${e}`);
    }

    const messageSentSuccess = () => {
      message.success({ content: 'Message sent!', key, duration: 3 });
      this.setState({
        sendDisabled: false,
      });
      this.resetFormFields();
    };

    const messageSentFailed = () => {
      message.error({ content: 'Error in sending message!', key, duration: 3 });
      this.setState({
        sendDisabled: false,
      });
    };

    const onFinish = values => {
      console.log(values);
      this.setState({
        sendDisabled: true,
      });
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'contact', ...values }),
      })
        .then(() => {
          console.log('Form submission success');
          messageSentSuccess();
        })
        .catch(error => {
          console.error('Form submission error:', error);
          handleNetworkError();
          messageSentFailed();
        });
    };

    return (
      <div className="contactForm">
        <Form
          {...layout}
          ref={this.formRef}
          name="contact"
          onFinish={onFinish}
          validateMessages={validateMessages}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <Form.Item name="name" label="Name">
            <Input name="name" placeholder="Your name" aria-label="name" />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: 'email',
                required: true,
              },
            ]}
          >
            <Input
              name="email"
              placeholder="Email address"
              aria-label="email"
            />
          </Form.Item>
          <Form.Item
            name="message"
            label="Message"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.TextArea
              name="message"
              placeholder="Your message"
              aria-label="message"
              autoSize={{ minRows: 4, maxRows: 10 }}
            />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button
              type="primary"
              shape="round"
              htmlType="submit"
              className="sendButton"
              disabled={this.state.sendDisabled}
            >
              Send
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default ContactFormComp;
