import Link from 'gatsby-link';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import SectionContainer from '../components/SectionContainer';
import SectionHeader from '../components/SectionHeader';
import WhiteBox from '../components/WhiteBox';
import connectkid from '../images/connect-kid.jpg';
import gaana from '../images/gaana.jpg';
import portfolio from '../images/portfolio.png';
import salesforceDev1 from '../images/salesforce.jpeg';
import { rhythm } from '../utils/typography';

const PerformanceSection = () => {
  const performances = [
    {
      name: 'Connect Kid',
      description:
        "Interaction platform between day care teachers and parents. Real time notifications for all kid's activities and attendance. Android, iOS and Web application.",
      techStack: [
        { name: 'React', description: 'UI' },
        { name: 'React Native', description: 'Mobile' },
        { name: 'Spring framework', description: 'Backend' },
      ],
      webpageURL:
        'https://play.google.com/store/apps/details?id=au.com.sood.connectkid.platform.connectkidappv2',
      imgPath: connectkid,
    },
    {
      name: 'Gaana.com Google Chrome extension',
      description:
        "Media controllers for www.gaana.com in a Google Chrome extension. Let's you control the songs from any tab without switching to tab playing the song.",
      techStack: [
        { name: 'Javascript', description: 'Front-end Framework' },
        { name: 'HTML', description: 'Front-end Framework' },
        { name: 'CSS', description: 'Front-end styling' },
      ],
      webpageURL:
        'https://chrome.google.com/webstore/detail/gaana-media-controller/pefpkgaogefkcipobonepaoflmijbacb',
      imgPath: gaana,
    },
    {
      name: 'Portfolio',
      description:
        'This website. Started with Gatsby starter kit and created components for the whole site including Google analytics and SEO on each page.',
      techStack: [
        { name: 'Gatsby', description: 'Static Site Generator' },
        { name: 'React', description: 'UI' },
        { name: 'GraphQL', description: 'Query language' },
        { name: 'Netlify CMS', description: 'CMS' },
      ],
      webpageURL: 'https://www.niteshsood.com',
      imgPath: portfolio,
    },
    {
      name: 'Salesforce Certified Platform Developer 1',
      description:
        'I recently passed the SFPD1 certification. Also completed few super badges before attempting the certification.',
      techStack: [
        { name: 'SF fundamentals', description: 'Salesforce Fundamentals' },
        { name: 'DMM', description: 'Data Modelling and Management' },
        { name: 'Process', description: 'Process automation and logic' },
        { name: 'User Interface', description: 'Salesforce Lightning web components' },
        { name: 'Testing, Deployment', description: 'Testing, Debugging and Deployment' },
      ],
      webpageURL: 'https://trailblazer.me/id/sood',
      imgPath: salesforceDev1,
    },
  ];

  const styles = {
    grid: {
      display: 'grid',
      gridGap: rhythm(2),
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 350px))',
      justifyContent: 'center',
      margin: 'auto',
      maxWidth: 1200,
      textDecoration: 'none',
    },
    whiteBox: {
      padding: rhythm(1),
      color: 'black',
    },
    itemImage: {
      border: '1px solid #aaa',
      objectFit: 'cover',
      height: '160px',
      width: '100%',
      filter: 'brightness(95%) grayscale(30%)',
    },
    itemTitle: {
      color: '#262626',
      display: 'block',
      fontWeight: 'bold',
      margin: '1rem 0',
      textDecoration: 'none',
    },
    description: {
      fontSize: '0.8rem',
      marginBottom: '0.8rem',
    },
    cardLink: {
      textDecoration: 'none',
    },
    cardTitle: {
      padding: '20px 0 10px 0',
    },
    externalLink: {
      color: '#999',
      fontSize: '0.8rem',
      textDecoration: 'none',
      marginRight: '1rem',
      ':hover': {
        background: '#eee',
      },
    },
    techStackGrid: {
      borderTop: '1px dotted #999',
      display: 'grid',
      fontSize: '0.8rem',
      gridGap: '0.4rem',
      gridTemplateColumns: '1fr 1fr',
      marginTop: '0.8rem',
      paddingTop: '1rem',
      ' > span': {
        cursor: 'default',
      },
      ' > span::before': {
        content: '- ',
      },
    },
  };

  return (
    <SectionContainer id="projects">
      <SectionHeader text="Projects" colorNumber="1" />

      {/* Grid start */}
      <div css={styles.grid}>
        {performances.map(performance => (
          <a
            href={performance.webpageURL}
            css={styles.cardLink}
            target="_blank"
            rel="noopener noreferrer"
            key={performance.name}
          >
            <WhiteBox key={performance.name} css={styles.whiteBox} hover>
              <img
                src={performance.imgPath}
                css={styles.itemImage}
                alt={performance.name}
              />

              <h4 style={styles.cardTitle}><b>{performance.name}</b></h4>

              <p css={styles.description}>{performance.description}</p>

              {performance.githubURL ? (
                <a
                  href={performance.githubURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  css={styles.externalLink}
                >
                  Source codes
                </a>
              ) : (
                <div style={{ color: 'transparent' }}>_</div>
              )}

              {performance.blogPath && (
                <Link
                  to={performance.blogPath}
                  target="_blank"
                  css={styles.externalLink}
                >
                  Related articles
                </Link>
              )}

              <ul css={styles.techStackGrid}>
                {performance.techStack.map(tech => (
                  <span
                    data-for={tech.name + tech.description}
                    data-tip
                    key={tech.name}
                  >
                    {tech.name}
                    <ReactTooltip
                      id={tech.name + tech.description}
                      effect="solid"
                    >
                      {tech.description}
                    </ReactTooltip>
                  </span>
                ))}
              </ul>
            </WhiteBox>
          </a>
        ))}
      </div>
      {/* Grid end */}
    </SectionContainer>
  );
};

export default PerformanceSection;
