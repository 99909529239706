import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import SectionContainer from '../components/SectionContainer';
import SectionHeader from '../components/SectionHeader';
import ContactFormComp from '../components/ContactFormComp';
import svgMail from '../icons/footer-mail.svg';
import svgFacebook from '../icons/footer-facebook.svg';
import svgInstagram from '../icons/footer-instagram.svg';
import svgLinkedIn from '../icons/footer-linkedIn.svg';

import { rhythm } from '../utils/typography';

const FooterSection = props => {
  const footerInfo = [
    {
      imgPath: svgMail,
      description: 'niteshsood03@gmail.com',
      link: 'mailto:niteshsood03@gmail.com',
    },
    {
      imgPath: svgLinkedIn,
      description: 'Nitesh Sood',
      link: 'https://www.linkedin.com/in/niteshsood03/',
    },
    {
      imgPath: svgInstagram,
      description: 'niteshsood',
      link: 'https://www.instagram.com/niteshsood',
    },
    {
      imgPath: svgInstagram,
      description: 'sood.fit',
      link: 'https://www.instagram.com/sood.fit',
    },
    {
      imgPath: svgFacebook,
      description: 'niteshsood03',
      link: 'https://www.facebook.com/niteshsood03/',
    },
  ];

  const styles = {
    container: {
      display: 'flex',
      gridColumnGap: rhythm(1),
      gridTemplateColumns: '400px',
      margin: 'auto',
      marginTop: '100px',
      justifyContent: 'space-evenly',
      maxWidth: '700px',
    },
    link: {
      color: '#fff',
      textDecoration: 'none',
    },
    ItemContainer: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      height: rhythm(5 / 2),
    },
    image: {
      width: '30px',
      height: '30px',
    },
    description: {
      color: '#fff',
      marginBottom: 0,
      paddingLeft: rhythm(1 / 2),
    },
    copyright: {
      color: '#999999',
    },
    copyrightContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '3em',
    },
  };

  return (
    <SectionContainer id="contact" colorNumber="2" skew skewFillBottom>
      <SectionHeader text="Contact" colorNumber="2" />
      <ContactFormComp />

      <div css={styles.container}>
        {footerInfo.map(info => (
          <a
            href={info.link || null}
            onClick={info.onClick || null}
            css={styles.link}
            target="_blank"
            rel="noopener noreferrer"
            key={info.description}
          >
            <div css={styles.ItemContainer}>
              <span data-for={info.description} data-tip key={info.imgPath}>
                <img src={info.imgPath} css={styles.image} alt="" />
                <ReactTooltip id={info.description} effect="solid">
                  {info.description}
                </ReactTooltip>
              </span>
            </div>
          </a>
        ))}
      </div>
      <div style={styles.copyrightContainer}>
        <div style={styles.copyright}>
          Created by Nitesh Sood | Copyright © {new Date().getFullYear()}
        </div>
      </div>
    </SectionContainer>
  );
};

FooterSection.propTypes = {
  setCurrentModal: PropTypes.func.isRequired,
};

export default FooterSection;
