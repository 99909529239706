import React from 'react';
import { Divider, Button } from 'antd';

import 'antd/dist/antd.css';

import AgeCalculator from '../components/AgeCalculator';
import SectionContainer from '../components/SectionContainer';
import SectionHeader from '../components/SectionHeader';
import WhiteBox from '../components/WhiteBox';
import profileJpg from '../images/profile.png';
import { rhythm } from '../utils/typography';
import './styles.css';

const ProfileSection = () => {
  const styles = {
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 350px))',
      gridGap: rhythm(2),
      justifyContent: 'center',
    },
    link: {
      color: '#666666',
      textDecoration: 'none',
      // ':hover': {
      //   color: '#1E2749',
      // },
    },
    whiteBox: {
      height: 'min-content',
      padding: rhythm(1),
      paddingTop: 0,
      textDecoration: 'none',
    },
    whiteBoxKeireki: {
      gridRowStart: 'span 2',
    },
    h3: {
      marginTop: rhythm(2),
      marginBottom: rhythm(1.5),
    },
    homePictureContainer: {
      marginBottom: rhythm(1.5),
      textAlign: 'center',
    },
    homePicture: {
      width: '200px',
      borderRadius: '50%',
    },
    homeListItem: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: rhythm(1),
    },
    homeItemName: {
      flex: 2,
      textAlign: 'right',
      marginRight: '1rem',
    },
    homeItemContent: {
      flex: 3,
    },
    keirekiGray: {
      color: '#999999',
      marginTop: '0.5em',
    },
    italics: {
      fontStyle: 'italic',
    },
    downloadResume: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: '2em',
      marginTop: '-1em',
    },
  };

  return (
    <SectionContainer id="profile">
      <SectionHeader text="Profile" colorNumber="1" />
      <div className="downloadResume">
      <a href="https://github.com/sood03/portfolio/raw/master/Nitesh-Sood-Resume.pdf" rel="noopener noreferrer" css={styles.downloadResume}>
          <Button type="primary" shape="round" htmlType="submit">
            Download Resume
          </Button>
      </a>

      </div>
      <div css={styles.container}>
        <WhiteBox css={styles.whiteBox}>
          <h3 css={styles.h3}>Summary</h3>
          <div css={styles.homePictureContainer}>
            <img src={profileJpg} css={styles.homePicture} alt="me" />
          </div>

          <div css={styles.homeListItem}>
            <div css={styles.homeItemName}>
              <b>Name</b>
            </div>
            <div css={styles.homeItemContent}>Nitesh Sood</div>
          </div>

          <div css={styles.homeListItem}>
            <div css={styles.homeItemName}>
              <b>Age</b>
            </div>
            <div css={styles.homeItemContent}>
              <AgeCalculator birthday="1992-10-03T10:40+05:30" />
            </div>
          </div>

          <div css={styles.homeListItem}>
            <div css={styles.homeItemName}>
              <b>Residence</b>
            </div>
            <div css={styles.homeItemContent}>Bangalore, India</div>
          </div>

          <div css={styles.homeListItem}>
            <div css={styles.homeItemName}>
              <b>Hobby</b>
            </div>
            <div css={styles.homeItemContent}>Burpees</div>
          </div>

          <div css={styles.homeListItem}>
            <div css={styles.homeItemName}>
              <b>Motto</b>
            </div>
            <div css={styles.homeItemContent}>
              Keep it moving
            </div>
          </div>
        </WhiteBox>

        <WhiteBox css={{ ...styles.whiteBox, ...styles.whiteBoxKeireki }}>
          <h3 css={styles.h3}>Career</h3>

          <Divider/>

          <div>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <div>Software Developer - <b><a css={styles.link} href="https://www.tekion.com/" target="_blank" rel="noopener noreferrer">Tekion</a></b></div>
            <div css={styles.keirekiGray}>(Dec 2020 - Present)</div>
          </div>

          <Divider/>

          <div>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <div>Software Developer - <b><a css={styles.link} href="https://www.khoros.com/" target="_blank" rel="noopener noreferrer">Khoros</a></b></div>
            <div css={styles.keirekiGray}>(Apr 2018 - Dec 2020)</div>
          </div>
          {/*<p>some description goes here.</p>*/}

          <Divider/>

          <div>
            <div>Member of Technical Staff - <b><a css={styles.link} href="https://www.oracle.com/" target="_blank" rel="noopener noreferrer">Oracle</a></b></div>
            <div css={styles.keirekiGray}>(Jun 2015 - Mar 2018)</div>
          </div>
          {/*<p>some description goes here.</p>*/}

          <Divider/>

          <div>
            <div>Software Developer Intern - <b><a css={styles.link} href="https://www.salesforce.com/" target="_blank" rel="noopener noreferrer">Salesforce</a></b></div>
            <div css={styles.keirekiGray}>(Jul 2014 - Jun 2015)</div>
          </div>
          {/*<p>some description goes here.</p>*/}

        </WhiteBox>

        <WhiteBox css={styles.whiteBox}>
          <h3 css={styles.h3}>Education</h3>

          <Divider/>

          <p>
            <b>BITS Pilani Goa </b><span css={styles.keirekiGray}>(Aug 2010 - Jul 2015)</span>
            <br />
          </p>
          <p>MSc. Mathematics & B.E. Computer Science</p>

          <Divider/>

          <p>
            <b>INSPIRE Scholarship </b><span css={styles.keirekiGray}>(Jul 2010 - Jun 2015)</span>
            <br />
          </p>
          <p>Department of Science & Technology, Govt. of India</p>

        </WhiteBox>
      </div>
    </SectionContainer>
  );
};

export default ProfileSection;
