import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import SectionContainer from '../components/SectionContainer';
import SectionHeader from '../components/SectionHeader';
import Skill from '../components/Skill';
import WhiteBox from '../components/WhiteBox';
import questionSvg from '../icons/question.svg';

const SkillSection = props => {
  const styles = {
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 350px))',
      gridGap: '3rem',
      justifyContent: 'center',
      maxWidth: '1200px',
      margin: 'auto',
    },
    whiteBox: {
      padding: '2rem',
    },
    containerKihon: {
      position: 'relative',
    },
    helpButton: {
      color: '#999999',
      position: 'absolute',
      right: '0',
      top: '2px',
      outline: 'none',
      width: '15px',
      cursor: 'pointer',
      ' img': { width: '1.2rem' },
    },
    otherListItem: {
      marginBottom: '1rem',
    },
    sonotaTitle: {
      color: '#262626',
      fontWeight: 'bold',
    },
    sonotaContent: {
      marginTop: '.5rem',
    },
    caret: {
      marginRight: '.5rem',
      opacity: 0.8,
    },
    link: {
      color: '#1E2749',
      textDecoration: 'none',
    },
    awsBadge: {
      width: '80px',
      marginRight: '0.5rem',
    },
  };

  const skills = {
    Frontend: {
      'JavaScript': 3,
      'React': 3,
      'React Native': 3,
      'Next.js': 2,
      'GraphQL': 2,
      'VisualForce': 1,
      'PWA': 1
    },
    Backend: {
      Java: 3,
      'Spring': 3,
      RDBMS: 3,
      'AWS': 2,
      'Node.js': 2,
      'Python': 2,
      'Apex': 1
    },
  };

  return (
    <SectionContainer id="skill" colorNumber="2" skew skewReverse>
      <SectionHeader text="Skills" colorNumber="2" />
      <div css={styles.grid}>
        <WhiteBox css={styles.whiteBox}>
          <div style={{ position: 'relative' }}>
            <h3>Frontend</h3>
            <div
              css={styles.helpButton}
              onClick={() => props.setCurrentModal('SKILL_HELP')}
              onKeyDown={e => {
                if (e.keyCode === 13) props.setCurrentModal('SKILL_HELP');
              }}
              role="button"
              tabIndex={0}
            >
              <img src={questionSvg} alt="hint button" />
            </div>
            {Object.keys(skills.Frontend).map(skill => (
              <Skill
                skillName={skill}
                skillLevel={skills.Frontend[skill]}
                key={skill}
              />
            ))}
          </div>
        </WhiteBox>

        <WhiteBox css={styles.whiteBox}>
          <div>
            <h3>Backend</h3>
            {Object.keys(skills.Backend).map(skill => (
              <Skill
                skillName={skill}
                skillLevel={skills.Backend[skill]}
                key={skill}
              />
            ))}
          </div>
        </WhiteBox>

        <WhiteBox css={styles.whiteBox}>
          <div>
            <h3>Others</h3>
            <ul>
              <li css={styles.otherListItem}>
                <div css={styles.sonotaTitle}>
                  <FontAwesomeIcon
                    icon="caret-square-right"
                    style={styles.caret}
                  />
                  Certifications
                </div>
                <div css={styles.sonotaContent}>
                  <a
                    href="https://trailblazer.me/id/sood"
                    target="_blank"
                    rel="noopener noreferrer"
                    css={styles.link}
                  >
                    Salesforce Certified Platform Developer 1
                  </a>
                </div>
                <div css={styles.sonotaContent}>
                  <a
                    href="https://www.linkedin.com/in/niteshsood03/"
                    target="_blank"
                    rel="noopener noreferrer"
                    css={styles.link}
                  >
                  Amazon Web Services Essential Training
                  </a>
                </div>
              </li>
              <li css={styles.otherListItem}>
                <div css={styles.sonotaTitle}>
                  <FontAwesomeIcon
                    icon="caret-square-right"
                    style={styles.caret}
                  />
                  Languages
                </div>
                <div css={styles.sonotaContent}>English, Hindi, Punjabi</div>
              </li>
              {/*<li css={styles.otherListItem}>*/}
              {/*  <div css={styles.sonotaTitle}>*/}
              {/*    <FontAwesomeIcon*/}
              {/*      icon="caret-square-right"*/}
              {/*      style={styles.caret}*/}
              {/*    />*/}
              {/*    Tools*/}
              {/*  </div>*/}
              {/*  <div css={styles.sonotaContent}>Intellij, VSCode</div>*/}
              {/*</li>*/}
            </ul>
          </div>
        </WhiteBox>
      </div>
    </SectionContainer>
  );
};

SkillSection.propTypes = {
  setCurrentModal: PropTypes.func.isRequired,
};

export default SkillSection;
